.container{
    background-color:#ffffff;
    height: 100%;
    width: 100%;
    min-height: 100vh;
}

.sub_container{
    display: flex;
    padding: 2rem;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;  */
}