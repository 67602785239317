.container{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    align-items:center ;
    width: 100%;
    justify-content: center;
    gap: 0;
    /* padding: 1rem; */
    background-color: #f1f3f4;
}

.tbl{
    width: 100%;
}

.col{
    text-align: center;
}



