.container{
    width: 100%;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e8f0fe;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.portal_name{
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    gap: 1rem;
}

.name{
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
    color: #143672;
}

.calendar{
    padding: 2rem;
}


.back_btn{
    border: none;
    background-color: #143672;
    /* width: 5rem; */
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: 0.5rem;
}


